.multifarm-widget-card {
  width: 100%;
  display: flex;
  flex-flow: column;
  border-radius: 1rem;
  box-sizing: border-box;
  background: #303659;
  color: white;
  padding: 1.25rem;
  margin: 10px 0;
  overflow: auto;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 992px) {
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      row-gap: 20px;
    }

    &-container {
      display: flex;
      flex-flow: column;
      row-gap: 10px;
      margin-bottom: 1.25rem;
      text-align: left;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    font-weight: 700;

    &-container {
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: 400px) {
        align-items: flex-start;
        flex-flow: column;
      }
    }
  }
}
