.multifarm-widget-info-list {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  height: fit-content;
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}
