.pie-chart-table {
  overflow: auto;
  display: flex;
  flex-flow: column;
  flex: 1;
  gap: 20px;

  &__title {
    width: 100%;
    display: inline-flex;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    color: var(--primary-text);
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;

    .multifarm-widget-chart-container {
      height: 180px;
      width: 200px;
      .multifarm-widget-chart {
        height: 100%;
      }
    }

    .multifarm-widget-table {
      min-width: 350px;
      border-spacing: unset;

      @media (max-width: 450px) {
        min-width: unset;
      }

      .multifarm-widget-table-head-cell {
        &:last-child {
          color: white;
        }
      }

      .multifarm-widget-table-cell {
        border-radius: 0 !important;
        border-top: none;
        background-color: transparent;
        font-size: 12px;
      }
    }
  }
}
