.multifarm-widget-input {
  display: flex;
  align-items: center;
  gap: 16px;

  &-field {
    width: 100%;
    max-width: 300px;
    outline: none;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #303659;
    background: transparent;
    color: #fff;
  }
}
