.multifarm-widget-progress {
  position: relative;
  width: 100%;
  max-width: 160px;
  background: #d9d9d9;
  border-radius: 10px;
  height: 4px;
  overflow: hidden;

  &-bar {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    background: #fc30f5;
  }
}
