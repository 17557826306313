:root {
  --main-bg: #201f26;
  --card-bg: #26242e;
  --primary-text: #ffffff;
  --secondary-text: #a5adbd;
  --accent-color: #79c8e7;
}

.clickable {
  cursor: pointer;
}

.bordered {
  border: 1px solid #79c8e7;
}

.flex-row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.multifarm-solana-realms {
  font-family: 'Inter';
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 30px 40px;
  min-height: 100vh;
  background: var(--main-bg);
  color: var(--primary-text);
  border-radius: 16px;

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  &__misconfiguration {
    margin: auto;
    font-size: 24px;
  }

  &__aside {
    flex: 1 1 25%;
  }

  &-page {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 3 0 70%;

    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-tabs {
        display: flex;
        gap: 20px;
      }
    }

    &__content {
      padding: 30px 24px;
      border-radius: 6px;
      background: var(--card-bg);
    }
  }
}

.highlight-text {
  color: #5dc9eb;
  font-weight: 700;
}

// Common components overriding

// Card styling
.multifarm-solana-realms-card {
  border-radius: 4px;
  background-color: #282732;
}

// Tooltip styling
.multifarm-widget-chart-tooltip,
.multifarm-widget-info-tooltip {
  background: #181e28;

  span {
    font-weight: 500;
    color: #5dc9eb;
  }
}

// Area chart borderless style
.borderless-area {
  .recharts-curve {
    stroke: none;
  }
}

// Table cell styling
.multifarm-widget-table-cell {
  border-radius: 0 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

thead {
  .multifarm-widget-table-head-cell:last-child {
    color: #5dc9eb;
    font-weight: 700;
  }
}

// Placeholder styling
.multifarm-widget-placeholder {
  padding: unset;
}

.multifarm-widget-card {
  margin: 0;
}

.multifarm-widget-table {
  tbody {
    .multifarm-widget-table-cell:nth-child(2) {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


@media screen and (max-width: 756px) {
  .multifarm-solana-realms {
    padding: 12px 10px 40px;
  }
}
