.public-DraftEditorPlaceholder-inner {
  color: #a3a3a3;
  font-size: 1em;
  font-weight: 400px;
  line-height: 1.25em;
}

.public-DraftEditor-content {
  color: #18181b;
  font-size: 1em;
  font-weight: 400px;
  line-height: 1.25em;
}
