.multifarm-solana-nft-card {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  min-width: 250px;
  min-height: 230px;
  width: 100%;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &_hidden {
      display: none;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      overflow: hidden;  
    }
  }

  &__collection-image {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 4px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  &__info {
    display: flex;
    flex-direction: column;
    color: var(--primary-text);

    &-title {
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
    }

    &-subtitle {
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      color: var(--secondary-text);
    }
  }
}
