.multifarm-widget-table-pagination {
  display: flex;
  align-items: center;
  width: fit-content;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: #fff;

  & svg {
    cursor: pointer;
    color: #50b6ff;
    font-size: 0.75rem;
    width: 16px;
    height: 16px;

    &:first-child {
      transform: rotate(-90deg);
    }

    &:last-child {
      transform: rotate(90deg);
    }
  }

  &[data-first='true'] {
    & svg {
      &:first-child {
        color: #bebebe;
        pointer-events: none;
      }
    }
  }

  &[data-last='true'] {
    & svg {
      &:last-child {
        color: #bebebe;
        pointer-events: none;
      }
    }
  }

  &-text {
    font-size: 0.875rem;
    font-weight: 700;
    color: #fff;
    margin: 0 0.5rem;

    @media (max-width: 992px) {
      font-size: 0.875rem;
    }
  }
}
