.multifarm-widget-checkbox-wrapper {
  .multifarm-widget-checkbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #272d49;
    border: 1px solid #33b6ff;
    border-radius: 4px;
    cursor: pointer;

    & svg {
      width: 12px;
      height: 12px;
      color: #fff;
    }

    &[data-active='true'] {
      background-color: #33b6ff;
    }

    &[data-disabled='true'] {
      cursor: unset;
    }

    &[data-active='true'][data-disabled='true'] {
      & svg {
        color: #b2bdff;
      }
    }
  }
  input[type='checkbox'] {
    position: absolute;
    appearance: none;
  }
}
