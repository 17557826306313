.multifarm-widget-accordion {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #303659;
  border-radius: 15px;
  margin-bottom: 1.25rem;
  padding: 10px 16px;
  width: 100%;

  &-summary {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    overflow: auto;

    @media (max-width: 992px) {
      align-items: center;
      padding: 1rem;
    }

    & > svg {
      width: 16px;
      height: 16px;
      font-size: 0.875rem;
      color: #fff;
      transform: rotate(180deg);
    }

    &[data-open='true'] {
      & > svg {
        transform: rotate(0deg);
      }
    }
  }

  &-content {
    padding: 1.25rem;
    color: #fff;

    @media (max-width: 992px) {
      padding: 1rem;
    }
  }

  &-title {
    font-size: 0.875rem;
    font-weight: 700;
    color: #fff;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
      font-size: 0.875rem;
    }
  }
}
