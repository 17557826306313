.multifarm-nft-explorer {
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    row-gap: 40px;
    column-gap: 20px;
    justify-items: center;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2.5rem;
  }

  &__title {
    font-size: 1.5rem;
  }

  &__back-btn {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__collection-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    row-gap: 40px;
    column-gap: 20px;
    justify-items: center;
  }
}
