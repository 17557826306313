.multifarm-widget-info-tooltip {
  border-radius: 0.625rem;
  background-color: #303659;
  border: 1px solid #272d49;

  &-trigger {
    width: 16px;
    height: 16px;
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    color: #fff;
  }

  & .tippy-arrow {
    color: white;
  }

  & .tippy-content {
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;

    @media (max-width: 400px) {
      font-size: 10px;
      padding: 4px 8px;
    }
  }
}

div[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
