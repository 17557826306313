.multifarm-widget-chart-tooltip {
  position: relative;
  display: flex;
  flex-flow: column;
  row-gap: 5px;
  padding: 1rem;
  border-radius: 0.625rem;
  border: none;
  background: linear-gradient(107.39deg, #474f7e 3.48%, #343b61 97.52%);
  box-shadow: 0px 4px 15px rgba(20, 6, 75, 0.1);
  color: white;
  line-height: 1.4;
  outline: 0;
  white-space: normal;
  transition-property: transform, visibility, opacity;

  &-tick {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
    font-size: 0.875rem;
    color: white;

    & span[data-color] {
      width: 10px;
      height: 10px;
      border-radius: 9999px;
      margin-right: 0.25rem;
      display: block;
    }

    & span[data-key] {
      margin-right: 5px;
    }
  }

  &-category {
    font-size: 0.875rem;
  }

  & span[data-footer] {
    padding-top: 0.75rem;
    text-align: center;
  }
}

.recharts-tooltip-wrapper {
  z-index: 1000;
  border: none;
}
