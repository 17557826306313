.multifarm-widget-tabs {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  border-radius: 10px;
  background-color: #373d66;
  color: #fff;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &[data-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &-scroll {
    @media (max-width: 992px) {
      min-width: fit-content;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }

  &-container {
    width: fit-content;
    height: auto;

    &-scroll {
      @media (max-width: 992px) {
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  &-tab {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.25rem 0.75rem;
    width: fit-content;
    height: 100%;
    font-size: 1rem;
    min-width: 60px;

    &[data-active='true'] {
      background: #50b6ff;
      color: #fff;
      border-radius: 9px;
    }

    @media (max-width: 992px) {
      padding: 0.125rem 0.75rem;
      font-size: 0.875rem;

      &[data-active='true'] {
        border-radius: 9px;
      }
    }
  }
}
