.multifarm-widget-pie-chart-table-card {
  &-container {
    display: grid;
    grid-template-columns: 1fr 3fr;

    .multifarm-widget-chart-container {
      width: 320px;
      align-self: center;
      justify-self: center;
    }

    .multifarm-widget-table {
      justify-self: center;
    }

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }
  }
}
