.multifarm-widget-chart {
  width: 100%;
  height: 320px;

  &-container {
    width: 100%;

    &[data-legends='true'] {
      display: grid;
      grid-template-columns: 1fr 100px;
      gap: 1.25rem;

      & > * {
        min-width: 0;
      }
    }
  }

  &[data-height='full'] {
    min-height: 320px;
    height: 100%;
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
