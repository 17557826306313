.multifarm-widget-info-list-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 10px;
  background-color: #272d49;
  padding: 10px 20px;
  border-radius: 15px;

  &-title,
  &-value {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
