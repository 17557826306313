.multifarm-widget-select {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background-color: #373d66;
  color: #fff;
  padding: 0.25rem 2.75rem 0.25rem 1.25rem;
  border: none;
  outline: none;
  cursor: pointer;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  font-size: 1rem;
  font-weight: 500;

  &-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    & svg {
      width: 10px;
      height: 10px;
      font-size: 0.75rem;
      color: #fff;
      position: absolute;
      right: 1.25rem;
      transform: rotate(180deg);
    }
  }
}
