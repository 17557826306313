.multifarm-widget-table-cell {
  padding: 0.75rem 0.5rem;
  height: 50px;
  min-height: fit-content;
  min-width: fit-content;
  background-color: #303659;

  @media (max-width: 992px) {
    height: 40px;
    padding: 0.25rem 0.5rem;
  }

  &[data-wrap='nowrap'] {
    white-space: nowrap;
  }

  &:first-child {
    padding-left: 1rem;
    border-radius: 1rem 0 0 1rem;
  }

  &:last-child {
    padding-right: 1rem;
    border-radius: 0 1rem 1rem 0;
  }

  &[data-align='center'] {
    & .multifarm-widget-table__content-cell {
      justify-content: center;
    }
  }

  &-color {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 9999px;
  }
}
