.multifarm-widget-line-chart-legends {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  gap: 10px;
}

.multifarm-widget-line-chart-legend {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0;

  & p {
    font-size: 0.75rem;
    font-weight: 600;
    color: #fff;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    &[data-colored] {
      max-width: calc(100% - 20px);
    }
  }

  & span[data-color] {
    display: flex;
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 9999px;
    margin: 0 0.75rem 0 0;
  }

  & span[data-subtitle] {
    font-size: 0.625rem;
    color: #fff;
  }

  & span[data-title] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
  }
}
