.multifarm-widget-slider {
  width: 95%;
  padding: 10px 0;

  &__track {
    top: 4px;
    /* Thickness of the track */
    height: 10px;
  }

  &__track-0 {
    /* Color of the track before the thumb */
    background-color: #fc30f5;
    border-radius: 5px;
  }

  &__track-1 {
    /* Color of the track after the thumb */
    background: white;
    border-radius: 5px;
  }

  &__thumb {
    top: -2px;
    /* Shape of the thumb: circle */
    width: 16px;
    height: 16px;
    border-radius: 100%;
    /* Remove default outline when selected */
    outline: none;
    border: 2px solid #fc30f5;
    box-shadow: 0 0 0 1px #fc30f5;
    background-color: #fff;
    cursor: pointer;
  }

  &__thumb-text {
    margin: 0;
    top: -30px;
    position: absolute;
  }

  &__mark {
    top: 15px;
  }
}
