.multifarm-widget-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
  table-layout: fixed;
  color: #fff;

  & * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 0.875rem;

    @media (max-width: 992px) {
      font-size: 0.75rem;
    }
  }

  &-container {
    margin: -1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-scrollable {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-content-cell {
    display: flex;
    align-items: center;

    &_center {
      justify-content: center;
    }
  }

  &-head-cell {
    font-weight: 600;
    padding: 0.75rem 0.5rem;
    position: relative;

    &:first-child {
      padding-left: 1.25rem;
    }

    &:last-child {
      padding-right: 1.25rem;
    }

    &[data-wrap='nowrap'] {
      white-space: nowrap;
    }
  }

  &-cell-pagination {
    position: absolute;
    top: -25px;
    left: 0;
  }

  &-header-tabs {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &-header-tabs-text {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0.75rem;
    color: #fff;

    @media (max-width: 992px) {
      font-size: 0.875rem;
    }
  }

  &-top-pagination {
    padding: 0.75rem 0;
    display: flex;
    justify-content: flex-end;
  }
}
